@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Masonry CSS */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1rem; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column > div {
  margin-bottom: 16px; /* Adjust this value as needed */
}

.my-masonry-grid_column {
  padding-left: 1rem; /* gutter size */
  background-clip: padding-box;
}

/* React Modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #ccc;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

/* Lightbox */
.lightbox-container {
  border: 2px solid #ccc;
  padding: 10px;
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
}

/* Optional: Style scrollbar for better appearance in dark mode */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 4px;
}