/* NavBar.css */
.navbar {
    position: fixed;
    width: 100%;
    background: transparent;
    padding: 1rem 2rem;
    z-index: 1000;
    top: 0;
    left: 0;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-title {
    font-size: 1.5rem;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .menu-icon-container {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .menu-icon {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .bar {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #fff;
    left: 0;
  }
  
  .bar:nth-child(1) {
    top: 0;
  }
  
  .bar:nth-child(2) {
    top: calc(50% - 2px);
  }
  
  .bar:nth-child(3) {
    bottom: 0;
  }
  
  .nav-links {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    display: none;
    flex-direction: column;
    width: 100%;
  }
  
  .nav-link {
    padding: 1rem 2rem;
    color: #000;
    text-decoration: none;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .nav-links {
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      flex-direction: row;
      align-items: center;
    }
  
    .nav-link {
      padding: 0 1rem;
    }
  }
  